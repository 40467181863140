import { useState, useRef, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Overlay, Tooltip } from 'react-bootstrap';
import { TOOLTIP_DELAY } from '@/storychief/constants/Constants';

const propTypes = {
  id: PropTypes.string.isRequired,
  content: PropTypes.node,
  placement: PropTypes.oneOf(['top', 'right', 'bottom', 'left']),
  isTooltipAccessible: PropTypes.bool,
  children: PropTypes.node,
};

const defaultProps = {
  placement: 'top',
  children: null,
  isTooltipAccessible: false,
  content: null,
};

function OverlayTooltip({ id, placement, content, children, isTooltipAccessible }) {
  // states
  const [showOverlay, setShowOverlay] = useState(false);

  // refs
  const isMouseOver = useRef(false);
  const eventContentRef = useRef(null);

  const show = useCallback(() => {
    if (!content) return;

    isMouseOver.current = true;

    setTimeout(() => {
      if (isMouseOver.current) {
        setShowOverlay(true);
      }
    }, TOOLTIP_DELAY);
  }, [content]);

  const hide = useCallback(() => {
    isMouseOver.current = false;

    setTimeout(
      () => {
        if (!isMouseOver.current) {
          setShowOverlay(false);
        }
      },
      isTooltipAccessible ? TOOLTIP_DELAY : 0,
    );
  }, [content]);

  useEffect(
    () => () => {
      isMouseOver.current = false;
    },
    [],
  );

  return (
    <>
      <Overlay
        show={showOverlay}
        placement={placement}
        shouldUpdatePosition
        target={eventContentRef.current}
      >
        <Tooltip
          id={`tooltip-${id}`}
          onMouseOver={show}
          onMouseOut={hide}
          onFocus={show}
          onBlur={hide}
          className={classNames({ 'pointer-events-none': !isTooltipAccessible })}
        >
          {content}
        </Tooltip>
      </Overlay>
      <span
        onMouseEnter={show}
        onMouseLeave={hide}
        onFocus={show}
        onBlur={hide}
        ref={eventContentRef}
      >
        {children}
      </span>
    </>
  );
}

OverlayTooltip.propTypes = propTypes;
OverlayTooltip.defaultProps = defaultProps;

export default OverlayTooltip;
